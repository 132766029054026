











import { Component, Vue } from 'vue-property-decorator';
import Splash from '@/components/Splash.vue';

@Component({
  components: {
    Splash,
  },
})
export default class Home extends Vue {}
