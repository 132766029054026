












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HelloWorld extends Vue {
  @Prop() private tagline!: string;

  @Prop() private name!: string;

  @Prop() private email!: string;

  @Prop() private phone!: string;
}
